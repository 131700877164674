"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiV1ExamConfigSchema = void 0;
const zod_1 = require("zod");
exports.ApiV1ExamConfigSchema = zod_1.z.object({
    examConfigs: zod_1.z.array(zod_1.z.object({
        id: zod_1.z.string(),
        maturity: zod_1.z.string(),
        name: zod_1.z.string(),
        examId: zod_1.z.string(),
        createdAt: zod_1.z.string(),
        updatedAt: zod_1.z.string(),
    })),
});
