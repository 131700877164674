"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiV1CommonQueryParamsSchema = exports.ApiV1ListResponseSchema = void 0;
const zod_1 = require("zod");
const ApiV1ListResponseSchema = (items) => zod_1.z.object({
    items: zod_1.z.array(items),
    aggregations: zod_1.z.any(),
    nextToken: zod_1.z.string().nullable(),
    total: zod_1.z.number(),
});
exports.ApiV1ListResponseSchema = ApiV1ListResponseSchema;
exports.ApiV1CommonQueryParamsSchema = zod_1.z.object({
    nextToken: zod_1.z.string().optional(),
    include: zod_1.z.array(zod_1.z.string()).optional(),
    objectiveAuthorityIds: zod_1.z.array(zod_1.z.string()).optional(),
});
__exportStar(require("./item"), exports);
__exportStar(require("./exam-config"), exports);
__exportStar(require("./exam-session"), exports);
__exportStar(require("./workspace"), exports);
