"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrintPerformanceAssessmentInputSchema = exports.PrintYearAtaGlanceInputSchema = exports.PrintVerificationDocumentInputSchema = exports.PrintExamInputSchema = exports.IdentityContextInputSchema = void 0;
const zod_1 = require("zod");
exports.IdentityContextInputSchema = zod_1.z.object({
    appId: zod_1.z.string(),
    userId: zod_1.z.string(),
    workspaceId: zod_1.z.string(),
    useSystemAdmin: zod_1.z.boolean().optional(),
});
exports.PrintExamInputSchema = zod_1.z.object({
    examId: zod_1.z.string(),
    theme: zod_1.z.string(),
    workspace: zod_1.z.string(),
    copyrightYear: zod_1.z.string(),
    date: zod_1.z.string(),
    username: zod_1.z.string(),
    coverPage: zod_1.z.boolean().nullable().optional(),
    answerKey: zod_1.z.boolean(),
    workspaceId: zod_1.z.string(),
    dualColumn: zod_1.z.boolean(),
    publisherName: zod_1.z.string().nullable().optional(),
});
exports.PrintVerificationDocumentInputSchema = zod_1.z.object({
    courseId: zod_1.z.string(),
    username: zod_1.z.string(),
    workspaceId: zod_1.z.string(),
});
exports.PrintYearAtaGlanceInputSchema = zod_1.z.object({
    courseId: zod_1.z.string(),
    username: zod_1.z.string(),
    workspaceId: zod_1.z.string(),
    weeks: zod_1.z.string().optional(),
    calendarId: zod_1.z.string().optional(),
    showStandardsInGroups: zod_1.z.boolean(),
    workspace: zod_1.z.string(),
    copyrightYear: zod_1.z.string(),
    date: zod_1.z.string(),
});
exports.PrintPerformanceAssessmentInputSchema = zod_1.z.object({
    performanceAssessmentId: zod_1.z.string(),
    username: zod_1.z.string(),
    workspace: zod_1.z.string(),
    copyrightYear: zod_1.z.string(),
    date: zod_1.z.string(),
    showStandards: zod_1.z.boolean(),
});
