"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiV1GetItemsRequestSchema = exports.ApiV1GetItemsResponseSchema = exports.ApiV1ItemSchema = void 0;
const zod_1 = require("zod");
const _1 = require(".");
exports.ApiV1ItemSchema = zod_1.z.object({
    id: zod_1.z.string(),
    idInc: zod_1.z.string().optional().nullable(),
    createdAt: zod_1.z.string().optional().nullable(),
    updatedAt: zod_1.z.string().optional().nullable(),
    name: zod_1.z.string().optional().nullable(),
    workspaceId: zod_1.z.string().optional().nullable(),
    alignments: zod_1.z.array(zod_1.z.unknown()).optional().nullable(),
    content: zod_1.z
        .object({
        questions: zod_1.z.array(zod_1.z.object({
            id: zod_1.z.string(),
            interactionType: zod_1.z.string(),
        })),
    })
        .optional()
        .nullable(),
});
exports.ApiV1GetItemsResponseSchema = (0, _1.ApiV1ListResponseSchema)(exports.ApiV1ItemSchema);
exports.ApiV1GetItemsRequestSchema = zod_1.z.object({
    nextToken: zod_1.z.string().optional(),
    include: zod_1.z.array(zod_1.z.enum(["objectives", "content"])).optional(),
    objectiveAuthorityIds: zod_1.z.array(zod_1.z.string()).optional(),
    filter: zod_1.z
        .object({
        itemIds: zod_1.z
            .object({
            include: zod_1.z.array(zod_1.z.string()).optional(),
        })
            .optional(),
    })
        .optional(),
});
