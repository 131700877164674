"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExamSessionStatus = exports.ExamConfigStatus = void 0;
var ExamConfigStatus;
(function (ExamConfigStatus) {
    ExamConfigStatus["Pending"] = "PENDING";
    ExamConfigStatus["InProgress"] = "IN_PROGRESS";
    ExamConfigStatus["Cancelled"] = "CANCELLED";
    ExamConfigStatus["CompleteNeedsScoring"] = "COMPLETE_NEEDS_SCORING";
    ExamConfigStatus["CompleteSendOutcomes"] = "COMPLETE_SEND_OUTCOMES";
    ExamConfigStatus["Complete"] = "COMPLETE";
})(ExamConfigStatus || (exports.ExamConfigStatus = ExamConfigStatus = {}));
var ExamSessionStatus;
(function (ExamSessionStatus) {
    ExamSessionStatus["Pending"] = "PENDING";
    ExamSessionStatus["InProgress"] = "IN_PROGRESS";
    ExamSessionStatus["Cancelled"] = "CANCELLED";
    ExamSessionStatus["Paused"] = "PAUSED";
    ExamSessionStatus["CompleteNeedsScoring"] = "COMPLETE_NEEDS_SCORING";
    ExamSessionStatus["CompleteSendOutcomes"] = "COMPLETE_SEND_OUTCOMES";
    ExamSessionStatus["CompleteUpdateResults"] = "COMPLETE_UPDATE_RESULTS";
    ExamSessionStatus["Complete"] = "COMPLETE";
})(ExamSessionStatus || (exports.ExamSessionStatus = ExamSessionStatus = {}));
