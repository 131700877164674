import React from "react";
import styled, { keyframes } from "styled-components";

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Styled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  flex-direction: column;
  gap: 1rem;
  color: #999;

  svg {
    /* fill: blue; */
    width: 4.8rem;
  }

  .spinner {
    animation: ${spinnerAnimation} 0.75s linear infinite;
    transform-origin: center;
  }
`;

/** This will play a fancy animation as the application loads. */
const AppLoading: React.FC = (props) => {
  return (
    <Styled>
      <svg width="48" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
          opacity=".25"
        />
        <g>
          <circle className="spinner" cx="12" cy="2.5" r="1.5" />
        </g>
      </svg>
      <div>Loading TCMPC Curriculum</div>
    </Styled>
  );
};

export default AppLoading;
