"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiV1CreateWorkspaceRequestSchema = exports.ApiV1UpdateWorkspaceRequestSchema = exports.ApiV1PutWorkspaceProductsSchema = exports.US_STATES_PLAIN = void 0;
const zod_1 = require("zod");
// unfortunately, this is copied from `@examspark/common` as `types` does not
// depend on `common`, nor should it. I'm not sure where this really belongs in
// the big scheme of things.
exports.US_STATES_PLAIN = [
    "US-AL",
    "US-AK",
    "US-AZ",
    "US-AR",
    "US-CA",
    "US-CO",
    "US-CT",
    "US-DE",
    "US-FL",
    "US-GA",
    "US-HI",
    "US-ID",
    "US-IL",
    "US-IN",
    "US-IA",
    "US-KS",
    "US-KY",
    "US-LA",
    "US-ME",
    "US-MD",
    "US-MA",
    "US-MI",
    "US-MN",
    "US-MS",
    "US-MO",
    "US-MT",
    "US-NE",
    "US-NV",
    "US-NH",
    "US-NJ",
    "US-NM",
    "US-NY",
    "US-NC",
    "US-ND",
    "US-OH",
    "US-OK",
    "US-OR",
    "US-PA",
    "US-RI",
    "US-SC",
    "US-SD",
    "US-TN",
    "US-TX",
    "US-UT",
    "US-VT",
    "US-VA",
    "US-WA",
    "US-WV",
    "US-WI",
    "US-WY",
    "US-DC",
    "US-AS",
    "US-GU",
    "US-MP",
    "US-PR",
    "US-VI",
    "US-UM",
    "US-AE",
    "US-AA",
    "US-AP",
];
exports.ApiV1PutWorkspaceProductsSchema = zod_1.z.array(zod_1.z.object({
    bank: zod_1.z.object({
        id: zod_1.z.string(),
    }),
}));
exports.ApiV1UpdateWorkspaceRequestSchema = zod_1.z
    .object({
    name: zod_1.z.string(),
    ncesId: zod_1.z.string().optional(),
    type: zod_1.z.enum(["CAMPUS", "DISTRICT"]),
    /**
     * `undefined` means its neither setting nor deleting; `null` explicitly
     * means signals to delete the parent relationship
     */
    parentWorkspaceId: zod_1.z.string().optional().nullable(),
    requireChildWorkspaceSelection: zod_1.z.boolean().optional(),
    address: zod_1.z.object({
        city: zod_1.z.string(),
        state: zod_1.z.enum(exports.US_STATES_PLAIN),
        country: zod_1.z.enum(["US"]),
    }),
    products: zod_1.z.array(zod_1.z.object({
        bank: zod_1.z.object({
            id: zod_1.z.string(),
        }),
    })),
    objectiveDocuments: zod_1.z.array(zod_1.z.string()).optional(),
})
    .strict();
exports.ApiV1CreateWorkspaceRequestSchema = zod_1.z
    .object({
    name: zod_1.z.string(),
    id: zod_1.z.string().optional(),
    ncesId: zod_1.z.string().optional(),
    type: zod_1.z.enum(["CAMPUS", "DISTRICT"]),
    /**
     * `undefined` means its neither setting nor deleting; `null` explicitly
     * means signals to delete the parent relationship
     */
    parentWorkspaceId: zod_1.z.string().optional().nullable(),
    requireChildWorkspaceSelection: zod_1.z.boolean().optional(),
    address: zod_1.z.object({
        city: zod_1.z.string(),
        state: zod_1.z.enum(exports.US_STATES_PLAIN),
        country: zod_1.z.enum(["US"]),
    }),
    products: zod_1.z.array(zod_1.z.object({
        bank: zod_1.z.object({
            id: zod_1.z.string(),
        }),
    })),
    objectiveDocuments: zod_1.z.array(zod_1.z.string()).optional(),
})
    .strict();
