"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildContext = exports.asOIDCIdentity = exports.asLambdaIdentity = exports.asIAMIdentity = exports.asCognitoIdentity = exports.isOIDCIdentity = exports.isLambdaIdentity = exports.isIAMIdentity = exports.isCognitoIdentity = void 0;
const isCognitoIdentity = (identity) => identity != null && "sub" in identity && "username" in identity;
exports.isCognitoIdentity = isCognitoIdentity;
const isIAMIdentity = (identity) => identity != null && "userArn" in identity;
exports.isIAMIdentity = isIAMIdentity;
const isLambdaIdentity = (identity) => identity != null && "resolverContext" in identity;
exports.isLambdaIdentity = isLambdaIdentity;
const isOIDCIdentity = (identity) => identity != null && "sub" in identity && !(0, exports.isCognitoIdentity)(identity);
exports.isOIDCIdentity = isOIDCIdentity;
const asCognitoIdentity = (identity) => ((0, exports.isCognitoIdentity)(identity) ? identity : undefined);
exports.asCognitoIdentity = asCognitoIdentity;
const asIAMIdentity = (identity) => (0, exports.isIAMIdentity)(identity) ? identity : undefined;
exports.asIAMIdentity = asIAMIdentity;
const asLambdaIdentity = (identity) => (0, exports.isLambdaIdentity)(identity) ? identity : undefined;
exports.asLambdaIdentity = asLambdaIdentity;
const asOIDCIdentity = (identity) => (0, exports.isOIDCIdentity)(identity) ? identity : undefined;
exports.asOIDCIdentity = asOIDCIdentity;
const buildContext = (ctx) => {
    var _a, _b, _c, _d;
    return Object.assign(Object.assign({ env: {}, stash: {}, result: {}, prev: {}, info: {
            fieldName: "",
            parentTypeName: "",
            variables: {},
            selectionSetGraphQL: "",
            selectionSetList: [],
        } }, ctx), { arguments: (_b = (_a = ctx.arguments) !== null && _a !== void 0 ? _a : ctx.args) !== null && _b !== void 0 ? _b : {}, args: (_d = (_c = ctx.args) !== null && _c !== void 0 ? _c : ctx.arguments) !== null && _d !== void 0 ? _d : {} });
};
exports.buildContext = buildContext;
