"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutletType = exports.ProductTier = exports.SearchableObjectiveSortableFields = exports.SearchableResourceSortableFields = exports.SearchableSortDirection = exports.RelationshipTypeEnum = exports.ResourceVisibilityType = exports.ResourcePriorityEnum = exports.ScoringMethodEnum = exports.ResourceStorePriceSchemeType = exports.ResourceCollectionType = exports.JobImportImportFormat = exports.JobExportContentFormat = exports.JobType = exports.JobStatus = exports.DocumentPrintMode = exports.ModelSortDirection = exports.UserType = exports.WorkspaceUserRole = exports.Weekday = void 0;
var Weekday;
(function (Weekday) {
    Weekday["Sunday"] = "SUNDAY";
    Weekday["Monday"] = "MONDAY";
    Weekday["Tuesday"] = "TUESDAY";
    Weekday["Wednesday"] = "WEDNESDAY";
    Weekday["Thursday"] = "THURSDAY";
    Weekday["Friday"] = "FRIDAY";
    Weekday["Saturday"] = "SATURDAY";
})(Weekday || (exports.Weekday = Weekday = {}));
var WorkspaceUserRole;
(function (WorkspaceUserRole) {
    WorkspaceUserRole["Viewer"] = "VIEWER";
    WorkspaceUserRole["Author"] = "AUTHOR";
    WorkspaceUserRole["Editor"] = "EDITOR";
    WorkspaceUserRole["Standard"] = "STANDARD";
    WorkspaceUserRole["ContentManager"] = "CONTENT_MANAGER";
    WorkspaceUserRole["Administrator"] = "ADMINISTRATOR";
})(WorkspaceUserRole || (exports.WorkspaceUserRole = WorkspaceUserRole = {}));
var UserType;
(function (UserType) {
    UserType["AppsOnly"] = "APPS_ONLY";
})(UserType || (exports.UserType = UserType = {}));
var ModelSortDirection;
(function (ModelSortDirection) {
    ModelSortDirection["Asc"] = "ASC";
    ModelSortDirection["Desc"] = "DESC";
})(ModelSortDirection || (exports.ModelSortDirection = ModelSortDirection = {}));
var DocumentPrintMode;
(function (DocumentPrintMode) {
    DocumentPrintMode["Normal"] = "NORMAL";
    DocumentPrintMode["Binding"] = "BINDING";
})(DocumentPrintMode || (exports.DocumentPrintMode = DocumentPrintMode = {}));
var JobStatus;
(function (JobStatus) {
    JobStatus["Pending"] = "PENDING";
    JobStatus["InProgress"] = "IN_PROGRESS";
    JobStatus["Success"] = "SUCCESS";
    JobStatus["Failure"] = "FAILURE";
    JobStatus["Cancelled"] = "CANCELLED";
    JobStatus["CompleteSuccess"] = "COMPLETE_SUCCESS";
    JobStatus["CompleteFailure"] = "COMPLETE_FAILURE";
    JobStatus["Rejected"] = "REJECTED";
})(JobStatus || (exports.JobStatus = JobStatus = {}));
var JobType;
(function (JobType) {
    JobType["DeleteBank"] = "DELETE_BANK";
    JobType["ExportAppUsage"] = "EXPORT_APP_USAGE";
    JobType["ExportAppUsers"] = "EXPORT_APP_USERS";
    JobType["ExportContent"] = "EXPORT_CONTENT";
    JobType["ExportContentAlignments"] = "EXPORT_CONTENT_ALIGNMENTS";
    JobType["ExportContentMetadata"] = "EXPORT_CONTENT_METADATA";
    JobType["ExportExamQti"] = "EXPORT_EXAM_QTI";
    JobType["ExportObjectiveItemCounts"] = "EXPORT_OBJECTIVE_ITEM_COUNTS";
    JobType["ExportPassageMetadata"] = "EXPORT_PASSAGE_METADATA";
    JobType["ImportContent"] = "IMPORT_CONTENT";
    JobType["ImportContentMetadata"] = "IMPORT_CONTENT_METADATA";
    JobType["MergeBanks"] = "MERGE_BANKS";
    JobType["PublishContent"] = "PUBLISH_CONTENT";
    JobType["TaskItemReport"] = "TASK_ITEM_REPORT";
    JobType["ValidateContent"] = "VALIDATE_CONTENT";
    JobType["RebuildLicense"] = "REBUILD_LICENSE";
})(JobType || (exports.JobType = JobType = {}));
var JobExportContentFormat;
(function (JobExportContentFormat) {
    JobExportContentFormat["ImsQtiV2P0"] = "IMS_QTI_V2P0";
    JobExportContentFormat["ImsQtiV2P1"] = "IMS_QTI_V2P1";
    JobExportContentFormat["ImsQtiV2P2"] = "IMS_QTI_V2P2";
    JobExportContentFormat["ImsQtiV3P0"] = "IMS_QTI_V3P0";
})(JobExportContentFormat || (exports.JobExportContentFormat = JobExportContentFormat = {}));
var JobImportImportFormat;
(function (JobImportImportFormat) {
    JobImportImportFormat["ImsQtiV2P0"] = "IMS_QTI_V2P0";
    JobImportImportFormat["ImsQtiV2P1"] = "IMS_QTI_V2P1";
    JobImportImportFormat["ImsQtiV2P2"] = "IMS_QTI_V2P2";
    JobImportImportFormat["ImsQtiV3P0"] = "IMS_QTI_V3P0";
})(JobImportImportFormat || (exports.JobImportImportFormat = JobImportImportFormat = {}));
var ResourceCollectionType;
(function (ResourceCollectionType) {
    ResourceCollectionType["Standard"] = "STANDARD";
    ResourceCollectionType["Smart"] = "SMART";
})(ResourceCollectionType || (exports.ResourceCollectionType = ResourceCollectionType = {}));
var ResourceStorePriceSchemeType;
(function (ResourceStorePriceSchemeType) {
    ResourceStorePriceSchemeType["PerStudent"] = "PER_STUDENT";
    ResourceStorePriceSchemeType["PerSession"] = "PER_SESSION";
    ResourceStorePriceSchemeType["None"] = "NONE";
})(ResourceStorePriceSchemeType || (exports.ResourceStorePriceSchemeType = ResourceStorePriceSchemeType = {}));
var ScoringMethodEnum;
(function (ScoringMethodEnum) {
    ScoringMethodEnum["PerQuestion"] = "PER_QUESTION";
    ScoringMethodEnum["Dichotomous"] = "DICHOTOMOUS";
    ScoringMethodEnum["Dependent"] = "DEPENDENT";
    ScoringMethodEnum["CustomTxStaar"] = "CUSTOM_TX_STAAR";
})(ScoringMethodEnum || (exports.ScoringMethodEnum = ScoringMethodEnum = {}));
var ResourcePriorityEnum;
(function (ResourcePriorityEnum) {
    ResourcePriorityEnum["Info"] = "INFO";
    ResourcePriorityEnum["Low"] = "LOW";
    ResourcePriorityEnum["Medium"] = "MEDIUM";
    ResourcePriorityEnum["High"] = "HIGH";
    ResourcePriorityEnum["Critical"] = "CRITICAL";
})(ResourcePriorityEnum || (exports.ResourcePriorityEnum = ResourcePriorityEnum = {}));
var ResourceVisibilityType;
(function (ResourceVisibilityType) {
    ResourceVisibilityType["Private"] = "PRIVATE";
    ResourceVisibilityType["Workspace"] = "WORKSPACE";
    ResourceVisibilityType["WorkspaceShareable"] = "WORKSPACE_SHAREABLE";
})(ResourceVisibilityType || (exports.ResourceVisibilityType = ResourceVisibilityType = {}));
var RelationshipTypeEnum;
(function (RelationshipTypeEnum) {
    RelationshipTypeEnum["AlternateChild"] = "ALTERNATE_CHILD";
    RelationshipTypeEnum["AlternateParent"] = "ALTERNATE_PARENT";
    RelationshipTypeEnum["Enemy"] = "ENEMY";
    RelationshipTypeEnum["Suggestion"] = "SUGGESTION";
    RelationshipTypeEnum["TranslationChild"] = "TRANSLATION_CHILD";
    RelationshipTypeEnum["TranslationParent"] = "TRANSLATION_PARENT";
})(RelationshipTypeEnum || (exports.RelationshipTypeEnum = RelationshipTypeEnum = {}));
var SearchableSortDirection;
(function (SearchableSortDirection) {
    SearchableSortDirection["Asc"] = "asc";
    SearchableSortDirection["Desc"] = "desc";
})(SearchableSortDirection || (exports.SearchableSortDirection = SearchableSortDirection = {}));
var SearchableResourceSortableFields;
(function (SearchableResourceSortableFields) {
    SearchableResourceSortableFields["EsRandom"] = "_es_random_";
    SearchableResourceSortableFields["EsScore"] = "_es_score_";
    SearchableResourceSortableFields["AlignmentIds"] = "alignmentIds";
    SearchableResourceSortableFields["Author"] = "author";
    SearchableResourceSortableFields["BankId"] = "bankId";
    SearchableResourceSortableFields["Blooms"] = "blooms";
    SearchableResourceSortableFields["Content"] = "content";
    SearchableResourceSortableFields["ContextIds"] = "contextIds";
    SearchableResourceSortableFields["CreatedAt"] = "createdAt";
    SearchableResourceSortableFields["CreatorId"] = "creatorId";
    SearchableResourceSortableFields["Description"] = "description";
    SearchableResourceSortableFields["Dok"] = "dok";
    SearchableResourceSortableFields["DueAt"] = "dueAt";
    SearchableResourceSortableFields["EditorId"] = "editorId";
    SearchableResourceSortableFields["ExamItemAverageScore"] = "examItemAverageScore";
    SearchableResourceSortableFields["ExamItemLastUsedAt"] = "examItemLastUsedAt";
    SearchableResourceSortableFields["ExamItemUseCount"] = "examItemUseCount";
    SearchableResourceSortableFields["Excerpt"] = "excerpt";
    SearchableResourceSortableFields["Foo"] = "foo";
    SearchableResourceSortableFields["Grade"] = "grade";
    SearchableResourceSortableFields["Id"] = "id";
    SearchableResourceSortableFields["Interaction"] = "interaction";
    SearchableResourceSortableFields["IsArchived"] = "isArchived";
    SearchableResourceSortableFields["IsDeleted"] = "isDeleted";
    SearchableResourceSortableFields["IsProcessing"] = "isProcessing";
    SearchableResourceSortableFields["Language"] = "language";
    SearchableResourceSortableFields["Maturity"] = "maturity";
    SearchableResourceSortableFields["Name"] = "name";
    SearchableResourceSortableFields["ObjectiveDocument"] = "objectiveDocument";
    SearchableResourceSortableFields["ObjectiveScope"] = "objectiveScope";
    SearchableResourceSortableFields["Reviews"] = "reviews";
    SearchableResourceSortableFields["SchemaVersion"] = "schemaVersion";
    SearchableResourceSortableFields["Sequence"] = "sequence";
    SearchableResourceSortableFields["Sk"] = "sk";
    SearchableResourceSortableFields["Subject"] = "subject";
    SearchableResourceSortableFields["Tags"] = "tags";
    SearchableResourceSortableFields["Type"] = "type";
    SearchableResourceSortableFields["UpdatedAt"] = "updatedAt";
    SearchableResourceSortableFields["Version"] = "version";
})(SearchableResourceSortableFields || (exports.SearchableResourceSortableFields = SearchableResourceSortableFields = {}));
var SearchableObjectiveSortableFields;
(function (SearchableObjectiveSortableFields) {
    SearchableObjectiveSortableFields["Id"] = "id";
    SearchableObjectiveSortableFields["Sk"] = "sk";
    SearchableObjectiveSortableFields["Guid"] = "guid";
    SearchableObjectiveSortableFields["Type"] = "type";
    SearchableObjectiveSortableFields["Name"] = "name";
    SearchableObjectiveSortableFields["Content"] = "content";
    SearchableObjectiveSortableFields["NumRaw"] = "numRaw";
    SearchableObjectiveSortableFields["NumEnhanced"] = "numEnhanced";
    SearchableObjectiveSortableFields["OutlineNumber"] = "outlineNumber";
    SearchableObjectiveSortableFields["Abbreviation"] = "abbreviation";
    SearchableObjectiveSortableFields["Sequence"] = "sequence";
    SearchableObjectiveSortableFields["IdAuthority"] = "idAuthority";
    SearchableObjectiveSortableFields["IdPublisher"] = "idPublisher";
    SearchableObjectiveSortableFields["ParentId"] = "parentId";
    SearchableObjectiveSortableFields["Scope"] = "scope";
    SearchableObjectiveSortableFields["IsAlignable"] = "isAlignable";
    SearchableObjectiveSortableFields["IsObsolete"] = "isObsolete";
    SearchableObjectiveSortableFields["ObjectiveTree"] = "objectiveTree";
    SearchableObjectiveSortableFields["WorkflowStatus"] = "workflowStatus";
    SearchableObjectiveSortableFields["VersionCurrent"] = "versionCurrent";
    SearchableObjectiveSortableFields["VersionNext"] = "versionNext";
    SearchableObjectiveSortableFields["VersionLabel"] = "versionLabel";
    SearchableObjectiveSortableFields["DocumentId"] = "documentId";
    SearchableObjectiveSortableFields["AuthorityId"] = "authorityId";
    SearchableObjectiveSortableFields["PublicationId"] = "publicationId";
    SearchableObjectiveSortableFields["Notes"] = "notes";
    SearchableObjectiveSortableFields["CreatedAt"] = "createdAt";
    SearchableObjectiveSortableFields["UpdatedAt"] = "updatedAt";
})(SearchableObjectiveSortableFields || (exports.SearchableObjectiveSortableFields = SearchableObjectiveSortableFields = {}));
var ProductTier;
(function (ProductTier) {
    ProductTier["Free"] = "FREE";
    ProductTier["Basic"] = "BASIC";
    ProductTier["Professional"] = "PROFESSIONAL";
    ProductTier["Enterprise"] = "ENTERPRISE";
})(ProductTier || (exports.ProductTier = ProductTier = {}));
var OutletType;
(function (OutletType) {
    OutletType["Learnosity"] = "LEARNOSITY";
})(OutletType || (exports.OutletType = OutletType = {}));
