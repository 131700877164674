"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiV1GetExamSessionResponseSchema = exports.ApiV1GetExamSessionsResponseSchema = exports.ApiV1ExamSessionSchema = exports.ApiV1ExamSessionResponsesSchema = exports.ApiV1ExamSessionResultsSchema = exports.ApiV1ExamSessionResultsObjectiveSchema = exports.ApiV1GetExamSessionsRequestSchema = void 0;
const zod_1 = require("zod");
exports.ApiV1GetExamSessionsRequestSchema = zod_1.z.object({
    include: zod_1.z.array(zod_1.z.enum(["items", "responses", "results"])).optional(),
    filter: zod_1.z
        .object({
        examIds: zod_1.z
            .object({
            include: zod_1.z.array(zod_1.z.string()).optional(),
        })
            .optional(),
        examConfigIds: zod_1.z
            .object({
            include: zod_1.z.array(zod_1.z.string()).optional(),
        })
            .optional(),
        examSessionIds: zod_1.z
            .object({
            include: zod_1.z.array(zod_1.z.string()).optional(),
        })
            .optional(),
    })
        .optional(),
});
exports.ApiV1ExamSessionResultsObjectiveSchema = zod_1.z.object({
    score: zod_1.z.object({
        actual: zod_1.z.number().optional().nullable(),
        isScorable: zod_1.z.boolean().optional().nullable(),
        min: zod_1.z.number().optional().nullable(),
        max: zod_1.z.number().optional().nullable(),
    }),
    scores: zod_1.z.array(zod_1.z.object({
        actual: zod_1.z.number().optional().nullable(),
        isScorable: zod_1.z.boolean().optional().nullable(),
        min: zod_1.z.number().optional().nullable(),
        max: zod_1.z.number().optional().nullable(),
    })),
    id: zod_1.z.string(),
});
exports.ApiV1ExamSessionResultsSchema = zod_1.z.object({
    objectives: zod_1.z.record(zod_1.z.string(), exports.ApiV1ExamSessionResultsObjectiveSchema),
    items: zod_1.z.array(zod_1.z.object({
        id: zod_1.z.string(),
        score: zod_1.z.object({
            actual: zod_1.z.number().optional().nullable(),
            isScorable: zod_1.z.boolean().optional().nullable(),
            min: zod_1.z.number().optional().nullable(),
            max: zod_1.z.number().optional().nullable(),
        }),
    })),
    points: zod_1.z.object({
        actual: zod_1.z.number().optional(),
        isScorable: zod_1.z.boolean().optional(),
        min: zod_1.z.number().optional(),
        max: zod_1.z.number().optional(),
    }),
});
exports.ApiV1ExamSessionResponsesSchema = zod_1.z.record(zod_1.z.string(), zod_1.z.object({
    raw: zod_1.z.record(zod_1.z.string(), zod_1.z.any()),
    processed: zod_1.z.record(zod_1.z.string(), zod_1.z.any()),
}));
exports.ApiV1ExamSessionSchema = zod_1.z.object({
    examSession: zod_1.z.object({
        id: zod_1.z.string(),
        studentId: zod_1.z.string().nullable(),
        maturity: zod_1.z.string().optional().nullable(),
        name: zod_1.z.string().optional().nullable(),
        scoreActual: zod_1.z.number().optional().nullable(),
        scoreMin: zod_1.z.number().optional().nullable(),
        scoreMax: zod_1.z.number().optional().nullable(),
        results: exports.ApiV1ExamSessionResultsSchema.optional(),
        responses: exports.ApiV1ExamSessionResponsesSchema.optional(),
    }),
    examConfig: zod_1.z.object({ id: zod_1.z.string().optional().nullable() }).optional(),
});
exports.ApiV1GetExamSessionsResponseSchema = zod_1.z.object({
    items: zod_1.z.array(exports.ApiV1ExamSessionSchema),
    // aggregations: z.object({
    //   minScore: z.object({ value: z.number() }),
    //   avgScore: z.object({ value: z.number() }),
    //   maxScore: z.object({ value: z.number() }),
    // }),
    aggregations: zod_1.z.any(),
    metadata: zod_1.z.any(),
    // metadata: z.object({
    //   exams: z.record(
    //     z.string(),
    //     z.object({
    //       id: z.string(),
    //       name: z.string(),
    //       description: z.string(),
    //       maturity: z.string(),
    //       createdAt: z.string(),
    //       updatedAt: z.string(),
    //       questionIds: z.array(z.string()),
    //       workspaceId: z.string(),
    //     })
    //   ),
    //   items: z.record(
    //     z.string(),
    //     z.object({
    //       id: z.string(),
    //       maturity: z.string(),
    //       name: z.string(),
    //       createdAt: z.string(),
    //       updatedAt: z.string(),
    //       workspaceId: z.string(),
    //     })
    //   ),
    //   objectives: z.record(
    //     z.string(),
    //     z.object({
    //       id: z.string().optional().nullable(),
    //       text: z.string().optional().nullable(),
    //       abbreviation: z.string().optional().nullable(),
    //     })
    //   ),
    // }),
    nextToken: zod_1.z.string().nullable(),
    total: zod_1.z.number(),
});
exports.ApiV1GetExamSessionResponseSchema = exports.ApiV1ExamSessionSchema;
