"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeksCurriculumUsageList = exports.TeksCurriculumUsageMeta = exports.TeksCategoryList = exports.TeksCategoryMeta = void 0;
exports.TeksCategoryMeta = {
    PROCESS: {
        label: "Process",
        abbreviation: "P",
    },
    READINESS: {
        label: "Readiness",
        abbreviation: "R",
    },
    SUPPORTING: {
        label: "Supporting",
        abbreviation: "S",
    },
    RTC: {
        label: "Recurring Themes and Concepts",
        abbreviation: "RTC",
    },
    SEP: {
        label: "Science and Engineering Practices",
        abbreviation: "SEP",
    },
};
exports.TeksCategoryList = [
    "PROCESS",
    "READINESS",
    "SUPPORTING",
    "RTC",
    "SEP",
];
exports.TeksCurriculumUsageMeta = {
    DIRECTLY_TAUGHT: {
        label: "Taught Directly",
        short: "Direct",
        abbreviation: "T",
    },
    DEVELOPING: {
        label: "Developing",
        short: "Dev.",
        abbreviation: "D",
    },
    ONGOING: {
        label: "Ongoing",
        short: "Ongoing",
        abbreviation: "O",
    },
};
exports.TeksCurriculumUsageList = [
    "DIRECTLY_TAUGHT",
    "DEVELOPING",
    "ONGOING",
];
